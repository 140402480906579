import { request } from '@/utils/request'

/**
 * 
 * @returns {进件管理}
 * 
 */
//进件列表查询
export function resourceList(data, pagenum, size) {
    return request({
        url: `/api/manage/incom/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}

//新增进件
export function addResource(data) {
    return request({
        url: `/api/manage/incom/addIncom`,
        method: 'post',
        data
    })
}

//编辑进件
export function updateResource(data, id) {
    return request({
        url: `/api/manage/incom/updateIncom/${id}`,
        method: 'put',
        data
    })
}

//id查询进件详情
export function queryIdResource(id) {
    return request({
        url: `/api/manage/incom/getIncomById/${id}`,
        method: 'get'
    })
}

//删除进件
export function deleResource(id) {
    return request({
        url: `/api/manage/incom/deleteIncom/${id}`,
        method: 'delete'
    })
}

//导出进件EXCEL
export function exportResource(data) {
    return request({
        url: `/api/manage/incom/exportExcel`,
        method: 'post',
        data,
        responseType: 'blob',//服务器返回的数据类型
        timeout: 500 * 1000,
    })
}